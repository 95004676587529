import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBAnimation,
  MDBCard,
  MDBCardBody,
} from "mdbreact"
import ContactForm from "../components/sections/contactForm"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHero from "../components/pageHero"

function ContactPage({ data }) {
  return (
    <Layout>
      <SEO title="Contact" />
      <PageHero
        title="Contact"
        subtitle="If you'd like to
        chat, send me a message."
      />
      <MDBContainer className="my-md-5 py-5 px-md-0">
        <MDBRow className="mx-auto">
          <MDBCol>
            <MDBAnimation type="fadeIn" delay="0.3s">
              <MDBCard>
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol size="12" md="6">
                      <ContactForm />
                    </MDBCol>
                    <MDBCol size="12" md="6" className="d-none d-md-block">
                      <Img
                        fluid={data.file.childImageSharp.fluid}
                        alt="Map of Charlotte"
                        className="d-block w-100"
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBAnimation>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Layout>
  )
}
export default ContactPage

export const query = graphql`
  query ContactQuery {
    file(relativePath: { eq: "clt-map.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 505, maxHeight: 473, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
